import React from "react";
import Form from 'react-bootstrap/Form'
import { createMarkup } from "../../../src/helpers.js"

class Answer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      goNext: false,
      valueSelect: '',
      valueItem: [{id: this.props.answer.idElementOption, value: this.props.answer.nameElementOption}]
    };
    this.handleInputChange = this.handleInputChange.bind(this)
  }


  /**
   * Handles inputs changes
   * @param  {Event} e
   * @return {void}
   */
  handleInputChange(e) {
      let id = e.currentTarget.id.split('-')
      let idOption = ''
      let value = e.currentTarget.value
      let label = ''
      if(id.length > 0 ){
        idOption = id[1];
        id = id[0];
      }
      if (e.key) {
        if (e.key === 'Enter') {
          this.props.fromChildToParentCallback(id,idOption, value,  true)
        }
        else {
          let input = document.getElementsByTagName('input')[0].value
          if (input.length >= 1) {
            setTimeout((e) => {
              this.props.fromChildToParentCallback(id,idOption, value, false, true)
            }, 200);
          }
        }
      }
      if(e.currentTarget.type === "radio"){
        setTimeout((e) => {
          this.props.fromChildToParentCallback(id, idOption, value,  true)
        }, 200);
      }
      if(e.currentTarget.nodeName === "A"){
          this.props.fromChildToParentCallback(id, idOption, value,  true)
      }
      if(e.currentTarget.nodeName === "SELECT"){
        const index = e.target.selectedIndex;
        let valueSelect = e.target[index].getAttribute('eventkey');
        let label = e.target[index].getAttribute('value');
        this.setState({
          valueSelect: valueSelect,
        })
        setTimeout((e) => {
          this.props.fromChildToParentCallback(id, valueSelect, label, true)
        }, 200);
        this.setState({
          valueSelect: ''
        })
      }
  }

  buildTagName(baseElementId, questionId) {
    return baseElementId + questionId;
  }
  buildTagId(baseElementId, questionId, optionId) {
    if(optionId){
      return  questionId +'-'+ optionId;// buildTagName(baseElementId, questionId) + '-' + optionId;
    }else {
      return questionId;
    }
  }

  getValue(){
    let elm = null
    this.state.valueItem.forEach((element, index) => {
      if (element.id === this.props.answer.id){
        elm = element.value
      }
    })
    return elm
  }


  createTextInput(question, answer, baseElementId) {
    return <div className="my-3 pt-3 pt-md-0"><input type="text" id={this.buildTagId(baseElementId, question.id)} name={this.buildTagId(baseElementId, question.id, 'value')} onKeyUp={this.handleInputChange} value={this.getValue()} onChange={(e) => {
      this.setState({
        valueItem: [ ...this.state.valueItem, {
          'id': e.target.id,
          'value': e.target.value
        }],
      });

    }}   /></div>

  }

  createRadioButton(question, answer, baseElementId) {
    return (
      <>
        <ul className={question.id==258?'mt-5 mt-md-0':''}>
          {
          question.options.map((option, key) =>
            option.properties != null && option.properties.disabled != true ?
              (answer.idElementOption == option.id) ?
                <li key={key}>
                  <input type="radio" id={this.buildTagId(baseElementId, question.id, option.id)} name={this.buildTagId(baseElementId, question.id, option.id) } defaultValue={option.value} onChange={this.handleInputChange} required={question.properties.required} checked/>
                  <div className="check">
                    <div className="inside"></div>
                  </div>
                  <label htmlFor={this.buildTagId(baseElementId, question.id, option.id)} dangerouslySetInnerHTML={createMarkup(option.title.English)}></label>
                </li>
                :
              <li key={key}>
                <input type="radio" id={this.buildTagId(baseElementId, question.id, option.id)} name={this.buildTagId(baseElementId, question.id, option.id) } defaultValue={option.value} onChange={this.handleInputChange} required={question.properties.required}/>
                <div className="check">
                  <div className="inside"></div>
                </div>
                <label htmlFor={this.buildTagId(baseElementId, question.id, option.id)} dangerouslySetInnerHTML={createMarkup(option.title.English)}></label>
              </li>
              : ''
          )
        }
      </ul>
      </>
    )
  }
  createImage(question, baseElementId) {
    return (
      <>
        <div className="options">
          {
            question.options.map((option, key) =>
              option.properties != null && option.properties.disabled != true ?
                <div key={key} className="mx-md-3 py-md-0 py-4">
                  <a href="#" id={this.buildTagId(baseElementId, question.id, option.id)} name={this.buildTagId(baseElementId, question.id, option.id) } onClick={this.handleInputChange} required={question.properties.required}>
                    <label htmlFor={this.buildTagId(baseElementId, question.id, option.id)} dangerouslySetInnerHTML={createMarkup(option.title.English)}></label>
                  </a>
                </div>
                : ''
            )
          }
        </div>
      </>
    )
  }
  createDropdown(question, answer, baseElementId) {
    return <Form.Control className="mt-3" as="select" size="sm" custom id={this.buildTagId(baseElementId, question.id, this.state.valueSelect)} name={this.buildTagId(baseElementId, question.id, 'value')} onChange={this.handleInputChange}>
      <option value="NoAnswer" label="-- Please Select --"></option>
      {
            question.options.map((option, key) =>
              <option className={option.id} eventKey={option.id} value={option.title.English} selected={answer.idElementOption==option.id?true:false}>{option.title.English}</option>
            )
      }
    </Form.Control>
  }

  createQuestionAnswerStructure(question, answer, baseElementId) {
    if(question.id != 270){
      switch(question._subtype) {
        case 'radio':
          return this.createRadioButton(question, answer, baseElementId);
        case 'textbox':
          return this.createTextInput(question, answer, baseElementId);
        case 'menu':
          return this.createDropdown(question, answer,  baseElementId);
        default:
          return null;
      }
    } else {
      switch(question._subtype) {
        case 'radio':
          return this.createImage(question, baseElementId);
        default:
          return null;
      }
    }

  }

  render() {

    const { data, question, answer, baseElementId } = this.props
    return (
      <>
        {this.createQuestionAnswerStructure(question, answer, baseElementId)}
      </>
    )
  }
}
export default Answer