import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from '../scss/page.scss'
import Section from "../components/section/section";
import Img from "gatsby-image/withIEPolyfill";
import {graphql, useStaticQuery} from "gatsby";
import {get} from "lodash";
import Process from "../components/screening/process";

class Screening extends React.Component {
  constructor(props) {
    super(props)
    const title = ''
    this.seo = {
      title: title,
      description: '',
      seoTitle: title,
    }
  }

  render() {
    const { data, children } = this.props
    const ctaUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.ctaUrl')}`
    const baseUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.base')}`
    return (
      <Layout containerClassNames={`page step-screening`} hiddenFooter={true} hiddenSticky={true} hiddenHeader={true}>
        <SEO { ...this.seo } />
        <Section className="process pb-md-2 px-0">
          <Process/>
        </Section>
      </Layout>
    )
  }
}

export default (props) => {
  const data = useStaticQuery(graphql`
    query ScreeningPageQuery {
      site {
        siteMetadata {
          certapetInfo {
            url {
              ctaUrl
            }
            url {
              base
            }
          }
        }
      } 
      
      
    }
  `)
  return (
    <Screening  {...props} data={data}/>
  )
};
