import React from "react"
import Step from './step.js'
import { createMarkup } from "../../../src/helpers.js"
import Answer from "./answer";
import Section from "../section/section";
import ProgressBar from 'react-bootstrap/ProgressBar'

const axios = require("axios")

class Process extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseElementId:'sgE-{formID}-12-{questionId}-{elementId}',
      form: 'sg_FormFor',
      formId: '',
      pageId: '',
      questions: [],
      rules: [],
      currentPage: 1,
      todosPerPage: 1,
      upperPageBound: 3,
      lowerPageBound: 0,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: 'disabled',
      pageBound: 3,
      surveyFormLink: '',
      error: false,
      percentage: '',
      currentNameElementOption: '',
      currentIdElement: '',
      currentIdElementOption: '',
      nameElementOption: '',
      idElement: '',
      idElementOption: '',
      lastPage:'',
      pageNumber: 1,
      isActive: false,
      goNext: false,
      specialRule: false,
      antCurrentNameElementOption: '',
      antCurrentIdElement: '',
      antCurrentIdElementOption: '',
      flagToSend: true,
      pag: 1
    };
    this.answers = [];
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
  }
  /**
   * Get all question items and rules from surveygizmo
   */
  componentDidMount(){
    axios.get(`/.netlify/functions/get-survey?id=5253859`)
      .then(response => {
        this.setState({...this.state, rules: response.data.pages[1].questions});
        this.setState({...this.state, questions: response.data.pages[0].questions});
        this.setState({...this.state, pageId: response.data.pages[0].id});
        this.setState({...this.state, formId: response.data.id});
        this.setState({...this.state, baseElementId: 'sgE-' + response.data.id + '-12-'});
      })
  };
  /**
   * Set initial values for buttons Back and Next
   */
  setPrevAndNextBtnClass(listid) {
    let totalPage = Math.ceil(this.state.questions.length / this.state.todosPerPage);
    this.setState({isNextBtnActive: 'disabled'});
    this.setState({isPrevBtnActive: 'disabled'});
    if(totalPage === listid && totalPage > 1){
      this.setState({isPrevBtnActive: ''});
    }
    else if(listid === 1 && totalPage > 1){
      this.setState({isNextBtnActive: ''});
    }
    else if(totalPage > 1){
      this.setState({isNextBtnActive: ''});
      this.setState({isPrevBtnActive: ''});
    }
  }
  /**
   * Clear radio buttons values
   */
  clearParts(){
    if(document.getElementsByClassName("check").length > 0){
      [document.querySelectorAll('input[type="radio"]')].map(
        (element, index) => {
          let nodes = Array.prototype.slice.call(element,0);
          nodes.forEach(function(node){
            node.checked = false;
          });
        }
      );
    }
  }
  /**
   * Set values for Back button
   */
  btnPrevClick() {
    this.setState({isNextBtnActive: ''});
    if(this.state.pag > 0){
      if((this.state.currentPage - this.state.pag)%this.state.pageBound === 0 ){
        this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
      }
      let listid = this.state.currentPage - this.state.pag;
      this.setState({ currentPage : listid});
      this.setPrevAndNextBtnClass(listid);
    }else{
      if((this.state.currentPage - 1)%this.state.pageBound === 0 ){
        this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
      }
      let listid = this.state.currentPage - 1;
      this.setState({ currentPage : listid});
      this.setPrevAndNextBtnClass(listid);
    }
    this.clearParts();
    this.setState({ pag : 1});
  }
  /**
   * Check and add or modify a value if exist inside array of answers
   */
  checkExistingValue(obj){
    let arr = this.answers;
    if(arr.length > 0){
      let auxIndex = 0;
      let replace = 0;
      let add = 1;
      let auxObj = obj
      arr.forEach((element, index) => {
        if (element.id === obj.id && element.nameElementOption !== obj.nameElementOption){
          replace = 1;
          auxIndex = index;
        } else if (element.id === obj.id) {
          add = 0;
        }
      })
      if (replace === 1) {
        arr[auxIndex] = obj;
        auxObj = obj;
      } else if (add === 1) {
        arr.push(obj)
        auxObj = obj;
      }

      if(auxObj  && auxObj.id === obj.id){
        this.setState({
          isActive: true
        })
      } else {
        this.setState({
          isActive: false
        })
      }
    }else{
      arr.push(
        obj
      )
    }
  }

  /**
   * Create string with variables to send surveygizmo info
   */
  createString(){
    let survey = ''
    this.answers.map(
      (element, index) => {
        if(element.idElementOption){
          survey += '&data['+element.id +']['+ element.idElementOption +']='+ element.nameElementOption
        }else{
          survey += '&data['+element.id +']='+ element.nameElementOption
        }
      }
    );
    if(survey != ''){
      this.setState({
        surveyFormLink:survey
      })
    }else{
      return false
    }
  }
  /**
   * Set values for Next button
   */
  btnNextClick(pag = 1) {
    this.setState({
      isNextBtnActive: 'disabled',
      specialRule: false
    })
    this.setState({lastPage: this.state.currentPage});
    this.setPercentage();

    if(isNaN(pag)){
      pag = 1
    } else if(pag === 2){
      pag = pag
    } else if(pag > 1){
      pag = pag
    }
    this.setState({ pag : pag});
    if((this.state.currentPage +pag) > this.state.upperPageBound ){
      this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
      this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage : listid});
    this.setPrevAndNextBtnClass(listid);
    if(this.state.idElementOption){
      let obj = {
        id: this.state.idElement,
        idElementOption : this.state.idElementOption,
        nameElementOption : this.state.nameElementOption
      }
      this.checkExistingValue(obj)
      this.createString();
    }else if (this.state.nameElementOption){
      let obj = {
        id: this.state.idElement,
        nameElementOption : this.state.nameElementOption
      }
      this.checkExistingValue(obj)
      this.createString();
    } else {
      this.setState({isActive: false});
      this.setState({isNextBtnActive: 'disabled'});
    }
    // Need to unckeck all the radio buttons created
    this.clearParts();
    if(document.getElementsByTagName("select")[0]) {
      let dropDown = document.getElementsByTagName("select")[0];
      dropDown.selectedIndex = 0;
    }
    //Scroll to top after each pagination
    window.scrollTo(0, 0)
    this.setState({isNextBtnActive: 'disabled'});
  }
  /**
   * Set values of current item from answers
   */
  receiveChildValue = (idElement = '', idElementOption = '', nameElementOption = '', goNext = false, enabledNext = false) => {
    if(enabledNext){
      this.setState({
        isNextBtnActive: '',
        specialRule: true
      })
    } else {
      this.setState({
        isNextBtnActive: 'disabled'
      })
    }
    if(goNext == true || enabledNext == true) {
      this.setState({
        currentNameElementOption: this.state.nameElementOption,
        currentIdElement: this.state.idElement,
        currentIdElementOption: this.state.idElementOption,
      })
      this.setState({
        nameElementOption: nameElementOption,
        idElement: idElement,
        idElementOption: idElementOption,
      })
      let go = false
      if(idElementOption){
        go = this.state.rules.some(v => (v.properties.show_rules?(v.properties.show_rules.atom2.value == idElement + '-' + idElementOption)?go=true:'':''));
      }
      if(goNext && go == false) {
        this.btnNextClick();
      }
    } else {
      this.setState({
        error: true
      })
    }
  }
  /**
   * Set progressbar porcentage
   */
  setPercentage = () => {
    if(this.state.currentPage >= this.state.lastPage) {
      let progress = Math.ceil(this.state.currentPage * 100 / this.state.questions.length + 5)
      this.setState({
        percentage: progress
      })
    }
  }
  /**
   * Send all answers to surveygizmo
   */
  sendForm(){
    if(this.state.flagToSend){
      let sendSurvey = null
      if(sendSurvey === null){
        sendSurvey = axios.post('/.netlify/functions/put-survey?id=5253859', {
          line_items: this.state.surveyFormLink
        }).then( res => res.data)
      }
      this.setState({
        flagToSend: false
      })
    }
  }

  render() {
    const { questions, currentPage, todosPerPage, isPrevBtnActive, isNextBtnActive } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = questions.slice(indexOfFirstTodo, indexOfLastTodo);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(questions.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }
    let isExists = false;
    let url = ''
    if(isExists == false){
      isExists = this.state.rules.some(
        v =>
          (v.properties.show_rules?
            (v.properties.show_rules.atom.value === this.state.idElement ) ?
              ( (v.properties.show_rules.atom2.value == this.state.idElement + '-' + this.state.idElementOption) ? url = v.properties.url : '')
              :
              ((v.properties.show_rules.atom.atom2 && v.properties.show_rules.atom.atom2.value.length > 0) ? v.properties.show_rules.atom.atom2.value.some(item => item === this.state.currentIdElement + '-' + this.state.currentIdElementOption ? url = v.properties.url
                : '')
                : '')
            :''));
    }

    if(isExists){
      this.sendForm()
    }
    const filterByRules = currentTodos.map((todo, index) => {
      let verificationIdComplete = null;
      let verificationId = null;
      if(isExists) {
        isExists = false
        window.location.href = url
      }else if(todo.properties.show_rules && todo.properties.show_rules.atom2.value) {
        verificationId = todo.properties.show_rules.atom.value;
        verificationIdComplete = todo.properties.show_rules.atom2.value[0].split('-');
        if(verificationIdComplete.length>0){
          verificationIdComplete = verificationIdComplete[1]
        }
        if (verificationIdComplete) {
          let goSimple = false
          let goNoSimple = false
          let goToNoSimple = false
          if(verificationId === this.state.idElement && verificationIdComplete === this.state.idElementOption || !this.state.idElementOption) {
            //Rule for each items navigations, case when show X items depends of a previous response or another item.
            goSimple = true
          } else if (verificationId === this.state.currentIdElement && verificationIdComplete === this.state.currentIdElementOption){
            goSimple = true
          } else if (this.state.idElement === this.state.currentIdElement && this.state.idElementOption === this.state.currentIdElementOption){
            goSimple = true
          } else if (verificationId === this.state.idElement && this.state.idElementOption == 10657){
            goToNoSimple = true
            goNoSimple = false
            goSimple = false
          } else if (this.state.idElementOption == 10629 && !this.state.currentIdElementOption){
            goToNoSimple = true
            goNoSimple = false
            goSimple = false
          } else {
            goNoSimple = true
            goSimple = false
          }
          if(goSimple){
            return false
          }else if(goNoSimple){
            this.btnNextClick(2)
          }else if(goToNoSimple){
            this.btnNextClick(3)
          }
        }
      }
    });

    const progressInstance = <ProgressBar now={this.state.percentage} label={this.state.percentage > 0 ? `${this.state.percentage}%`:''} className="ml-md-0" />;
    let ans = '';
    let awr = this.answers;
    const renderTodos = currentTodos.map((todo, index) => {
      if(awr.length > 0){
        awr.map(
          (element, index) => {
            if(element.id == todo.id){
              ans = element;
            }
          }
        );
      }
      let btnNext = <a onClick={this.btnNextClick} className={`${this.state.isNextBtnActive} py-md-3 px-md-5 py-0 px-0`}><span id="btnNext"></span></a>
      if(ans || this.state.specialRule){
        btnNext = <a onClick={this.btnNextClick} className="py-md-3 px-md-5 py-0 px-0"><span id="btnNext"></span></a>
      }else{
        btnNext = <a onClick={this.btnNextClick} className="disabled py-md-3 px-md-5 py-0 px-0"><span id="btnNext"></span></a>
      }
      return (
        <>
          <Section bgColor="#e6f5fa" className="screening-content px-0">
            <div className="row py-md-1 py-md-5 px-5">
              <div className="offset-md-4">
                <Step question={createMarkup(todo.title.English)}/>
              </div>
            </div>
          </Section>
          <Section bgColor="#dbf0f8" className="screening-answers py-md-2 pb-5 px-0">
            <div className="row">
              <div className="col-12 col-md-6 offset-md-4 offset-0 mt-md-5 mt-2">
                <div id="answer" className="col-12 col-md-9 answer">
                  <Answer question={todo} answer={ans} baseElementId={this.state.baseElementId} fromChildToParentCallback={this.receiveChildValue} />
                </div>
              </div>
            </div>
            <p className="text-md-center text-left mt-md-5 mb-md-5 my-0 pt-md-0 pt-4 pt-md-4 pb-5 pb-md-4">All product and Company names are Trademarks™ or Registered® trademarks of their respective holders.</p>
          </Section>
          <Section className="screening-process container-fluid px-0 pt-md-2">
            <div className="row mt-md-3">
                <div className="call-button col-3 col-md-2 offset-md-1">
                  <a onClick={this.btnPrevClick} className={`${this.state.isPrevBtnActive} py-md-3 px-md-5 py-0 px-0`}><span id="btnPrev"></span></a>
                </div>
                <div className="col-6 col-md-3 mt-md-0 mt-3 offset-md-2 p-0">
                  {progressInstance}
                </div>
                <div className="call-button col-3 col-md-2 offset-md-2">
                  {btnNext}
                </div>
            </div>
          </Section>
        </>
      )
    });
    return (
        <>
        {renderTodos}
        </>
    )
  }
}

export default Process