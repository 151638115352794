import React from "react";
import {graphql, useStaticQuery} from "gatsby";

class Step extends React.Component {
  render() {

    const { data, key, question } = this.props
    return (
      <>
        <div className="col-9 col-md-8 offset-3 offset-md-0 question text-center">
          <span key={key} dangerouslySetInnerHTML={question}></span>
        </div>
      </>
    )
  }
}
export default (props) => {
  const data = useStaticQuery(graphql`
    query StepQuery {
      testImg: file(relativePath: { eq: "page-icon/easy.png" }) {
        childImageSharp { 
          fluid(maxWidth: 229, maxHeight: 208) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
    }
  `)
  return (
    <Step  {...props} data={data}/>
  )
};
